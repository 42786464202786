import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
    getDiscountsRecordsCount,
    getretrieveDiscounts,
} from "../modules/JobAndQuoteManager";
import { showSpinner, closeSpinner, modal, alert } from "../modules/Dialogs";
import { sessionExpired } from "../config/GeneralConfig";
import { toScreen } from "../modules/Routing";
import PropTypes from "prop-types";
import withNavigate from "./withNavigate";
import moment from "moment-timezone";
import { FormControl, MenuItem, Select } from "@mui/material";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import CreateDiscount from "./CreateDiscount";
import Pagination from "./Pagination";

const Discounts = ({ userDetails, navigate }) => {
    const [state, setState] = React.useState({
        right: false,
    });
    const [currentView, setCurrentView] = useState(null);
    const [selectedDiscount, setSelectedDiscount] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const drawerRef = useRef(null);

    const [open, setOpen] = useState(false);

    const [searchValue, setSearchValue] = useState(null);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState(
        window.history.state?.usr?.selectedFilter ?? "all"
    );
    const [pageSize, setPageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [inputValue, setInputValue] = useState(1);
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);

    const [sort, setSort] = useState("");
    const [discounts, setDiscounts] = useState([
        {
            _id: "1",
            codes: "SUMMER50",
            status: "Active",
            uses: "10/50",
            startDate: "2024-04-01",
            endDate: "2024-04-30",
        },
        {
            _id: "2",
            codes: "WELCOME10",
            status: "Expired",
            uses: "25/25",
            startDate: "2024-03-01",
            endDate: "2024-03-15",
        },
        {
            _id: "3",
            codes: "SPRING20",
            status: "Scheduled",
            uses: "0/100",
            startDate: "2024-05-01",
            endDate: "2024-05-15",
        },
        {
            _id: "4",
            codes: "FESTIVE30",
            status: "Expired",
            uses: "40/40",
            startDate: "2024-02-10",
            endDate: "2024-02-20",
        },
        {
            _id: "5",
            codes: "HOLIDAY15",
            status: "Active",
            uses: "8/20",
            startDate: "2024-04-05",
            endDate: "2024-04-20",
        },
        {
            _id: "6",
            codes: "FLASHDEAL25",
            status: "Scheduled",
            uses: "0/30",
            startDate: "2024-06-01",
            endDate: "2024-06-10",
        },
        {
            _id: "7",
            codes: "EASTER50",
            status: "Expired",
            uses: "50/50",
            startDate: "2024-03-20",
            endDate: "2024-03-31",
        },
        {
            _id: "8",
            codes: "WEEKEND10",
            status: "Active",
            uses: "12/25",
            startDate: "2024-04-06",
            endDate: "2024-04-07",
        },
        {
            _id: "9",
            codes: "WINTERSALE",
            status: "Expired",
            uses: "100/100",
            startDate: "2024-01-10",
            endDate: "2024-01-25",
        },
        {
            _id: "10",
            codes: "CYBERMONDAY",
            status: "Active",
            uses: "30/100",
            startDate: "2024-11-25",
            endDate: "2024-11-30",
        },
    ]);

    const toggleDrawer =
        (anchor, open, view = null, discount = null) =>
            (event) => {
                if (
                    event.type === "keydown" &&
                    (event.key === "Tab" || event.key === "Shift")
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });

                if (open) {
                    setCurrentView(view);
                    if (discount) {
                        setSelectedDiscount(discount);
                    } else {
                        setSelectedDiscount(null);
                    }
                }
            };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                isDrawerOpen &&
                drawerRef.current &&
                !drawerRef.current.contains(event.target)
            ) {
                closeDiscountDetails();
            }
        };

        // Add event listener when drawer is open
        if (isDrawerOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Cleanup listener
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isDrawerOpen]);

    const openDiscountDetails = (discount) => {
        setSelectedDiscount(discount);
        setIsDrawerOpen(true);
    };

    const closeDiscountDetails = () => {
        setIsDrawerOpen(false);
        setSelectedDiscount(null);
    };

    const [filterCount, setFilterCount] = useState({
        totalDocs: 0,
        openDiscounts: 0,
        closedDiscounts: 0,
        agreed: 0,
        paidDiscounts: 0,
        completedDiscounts: 0,
        refundRequestedDiscounts: 0,
        transferNeededDiscounts: 0,
    });

    const handleGoPage = () => {
        const newPage = Math.min(
            Math.max(Number(inputValue, 10) || 1, 1),
            totalPages
        );
        setPage(newPage);
    };

    useEffect(() => {
        setInputValue(page);
    }, [page]);

    const handlePageSize = (event) => {
        setPageSize(event.target.value);
        setPage(1);
        setInputValue(1);
    };

    const handlePrvPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const searchJob = () => {
        setSearch(searchValue);
        setFilter(searchValue === "" ? "all" : null);
        setPage(1);
    };

    const sortDiscounts = (event, field) => {
        if (event) event.preventDefault();

        const sortFields = {
            codes: { asc: "codes=1", desc: "codes=-1" },
            status: { asc: "status=1", desc: "status=-1" },
            startDate: { asc: "startDate=1", desc: "startDate=-1" },
            uses: { asc: "uses=1", desc: "uses=-1" },

            endDate: { asc: "endDate=1", desc: "endDate=-1" },
        };

        const currentSort = sortFields[field];
        if (!currentSort) return;

        const newSort =
            sort === currentSort.asc ? currentSort.desc : currentSort.asc;

        setSearch("");
        setSort(newSort);
        setPage(1);
    };

    // const getDiscounts = () => {
    //     showSpinner();

    //     const queryParams = {
    //         search,
    //         filter,
    //         sort,
    //         page: page,
    //         limit: pageSize,
    //     };

    //     getretrieveDiscounts(queryParams, getDiscounts_done, userDetails.jwttoken);
    // };

    // const getDiscounts_done = (resStatus, responseString, resJson) => {
    //     closeSpinner();

    //     if (resStatus === 401) {
    //         modal(sessionExpired);
    //         toScreen(false, navigate, "/logout");
    //     } else if (resStatus === "400") {
    //         setDiscounts([]);
    //         setPagination({
    //             page: 1,
    //             total: 1,
    //         });
    //         alert(responseString);
    //     } else if (resStatus === 200) {
    //         setTotalPages(Math.ceil(resJson?.totalRecords / pageSize));
    //         setDiscounts(discounts || []);
    //     }
    // };

    // const fetchFilterCount = () => {
    //     showSpinner();

    //     getDiscountsRecordsCount(handleFilterCount_done, jwtToken);
    // };

    // Handle filter count response
    // const handleFilterCount_done = (
    //     (resStatus, responseString, resJson) => {
    //         closeSpinner();

    //         if (resStatus === 401) {
    //             modal(sessionExpired);
    //             toScreen(false, navigate, "/logout");
    //         } else if (resStatus === "400") {
    //             alert(responseString);
    //         } else if (resStatus === 200) {
    //             setFilterCount({
    //                 totalDocs: resJson?.totalDocs,
    //                 openDiscounts: resJson?.openDiscounts,
    //                 closedDiscounts: resJson?.closedDiscounts,
    //                 paidDiscounts: resJson?.paidDiscounts,
    //                 completedDiscounts: resJson?.completedDiscounts,
    //                 refundRequestedDiscounts: resJson?.refundRequestedDiscounts,
    //                 transferNeededDiscounts: resJson?.transferNeededDiscounts,
    //             });
    //         }
    //     }
    // );

    const handleDiscountCheckbox = (discountId) => {
        if (selectedCheckbox.includes(discountId)) {
            setSelectedCheckbox(
                selectedCheckbox.filter((item) => item !== discountId)
            );
        } else {
            setSelectedCheckbox([...selectedCheckbox, discountId]);
        }
    };

    const renderDiscounts = (discount, index) => {
        const handleDelete = () => {
            if (modal("Are you sure you want to delete this discount?")) {
                const updatedDiscounts = discounts.filter(
                    (item) => item._id !== discount._id
                );
                setDiscounts(updatedDiscounts);
            }
        };

        return (
            <div className={`c-list-entry align-items-center`} key={index}>
                <div>
                    <form>
                        <div className="c-list-entry select-all-checkbox">
                            <input
                                type="checkbox"
                                id={`checkbox-${discount._id}`}
                                name="checkbox"
                                checked={selectedCheckbox.includes(discount._id)}
                                onChange={(e) =>
                                    handleDiscountCheckbox(discount._id, e.target.checked)
                                }
                            />
                            <label htmlFor={`checkbox-${discount?._id}`}></label>
                        </div>
                    </form>
                </div>
                <div className="c-list-entry__discountCode">
                    <b>{discount?.codes}</b>
                </div>
                <div className="c-list-entry__discountStatus">{discount?.status}</div>
                <div className="c-list-entry__uses">
                    <span className="u-before-icon text-capitalize">
                        &nbsp;&nbsp;{discount?.uses}
                    </span>
                </div>
                <div className="c-list-entry__startDate">
                    <span className="u-before-icon-calendar2">
                        &nbsp;&nbsp;
                        {discount?.startDate}
                    </span>
                    <br />
                    <span className="u-before-icon-clock">
                        &nbsp;&nbsp;
                        {moment(discount?.discountCreationDate)
                            ?.tz("Europe/London")
                            .format("hh:mm ")}
                    </span>
                </div>
                <div className="c-list-entry__startDate">
                    <span className="u-before-icon-calendar2">
                        &nbsp;&nbsp;
                        {discount?.endDate}
                    </span>
                    <br />
                    <span className="u-before-icon-clock">
                        &nbsp;&nbsp;
                        {moment(discount?.discountCreationDate)
                            ?.tz("Europe/London")
                            .format("hh:mm ")}
                    </span>{" "}
                </div>
                <div className="c-list-entry__actions">
                    {["right"].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <Button
                                className="btn btn-sm btn-primary me-5"
                                onClick={toggleDrawer(anchor, true, "edit", discount)}
                            >
                                Edit
                            </Button>
                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                                ModalProps={{
                                    BackdropProps: {
                                        style: { backgroundColor: "rgba(0, 0, 0, 0.1)" }
                                    }
                                }}
                                sx={{
                                    boxShadow: "none", // Removes shadow
                                    "& .MuiDrawer-paper": { boxShadow: "none" }
                                }}
                            >
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    ))}
                    <button
                        type="button"
                        className="c-btn c-btn--red c-btn--small me-0"
                        onClick={handleDelete}
                    >
                        Delete
                    </button>
                </div>
            </div>
        );
    };

    const list = (anchor) => (
        <Box
            sx={{ width: 600 }}
            role="presentation"
            onClick={(event) => event.stopPropagation()}
            onKeyDown={(event) => event.stopPropagation()}
        >
            <CreateDiscount
                view={currentView}
                discount={selectedDiscount}
                onClose={toggleDrawer(anchor, false)}
            />
        </Box>
    );

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        const inputValue = type === "checkbox" ? checked : value;

        if (name === "search") {
            setSearchValue(inputValue);
        } else if (name === "filter") {
            setSearch("");
            setSearchValue(null);
            setFilter(inputValue);
            setPage(1);
        }
    };

    const getSortClass = (key) => {
        if (sort === `${key}=1`) {
            return "c-list-filter__button c-list-filter__button--icon-up";
        } else if (sort === `${key}=-1`) {
            return "c-list-filter__button c-list-filter__button--icon-down";
        }
        return "c-list-filter__button c-list-filter__button--faded";
    };

    // useEffect(() => {
    //     getDiscounts();
    // }, [filter, page, sort, pageSize, getDiscounts]);

    // useEffect(() => {
    //     fetchFilterCount();
    // }, [fetchFilterCount]);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedCheckbox(discounts.map((discount) => discount._id));
        } else {
            setSelectedCheckbox([]);
        }
    };

    const handleDeleteSelected = () => {
        if (selectedCheckbox.length === 0) {
            alert("Please select discounts to delete");
            return;
        }

        const confirmDelete = modal(
            `Are you sure you want to delete ${selectedCheckbox.length} discount?`
        );

        if (confirmDelete) {
            const remainingDiscounts = discounts.filter(
                (discount) => !selectedCheckbox.includes(discount._id)
            );
            setDiscounts(remainingDiscounts);
            setSelectedCheckbox([]);
        }
    };

    return (
        <div className="l-base-layout__content">
            <h1>Admin / Discounts</h1>

            <div className="c-hr"></div>

            <div className="c-subheader-info-panel">
                <div className="c-subheader-info-panel__search">
                    <form className="d-flex align-items-center"
                    // onSubmit={(e) => {
                    //     e.preventDefault();
                    //     searchDiscount();
                    // }}
                    >
                        <div className="search-input-block">
                            <input
                                type="text"
                                value={searchValue || ""}
                                onChange={handleInputChange}
                                name="search"
                                placeholder="Search here..."
                                className="c-subheader-info-panel__input"
                            />
                            {searchValue && (
                                <svg
                                    onClick={() => setSearchValue("")}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="input-close-icon"
                                >
                                    <path d="M18 6 6 18"></path>
                                    <path d="m6 6 12 12"></path>
                                </svg>
                            )}
                        </div>

                        <button
                            type="button"
                            className="c-btn-outline c-btn-outline--white c-btn-outline--small"
                        // onClick={() => searchValue !== null && searchDiscount()}
                        >
                            Search
                        </button>
                    </form>
                </div>
            </div>

            <div className="my-16 text-end">
                {selectedCheckbox.length > 0 && (
                    <button
                        type="button"
                        className="c-btn c-btn--red c-btn--small mb-0"
                        onClick={handleDeleteSelected}
                    >
                        Delete Selected
                    </button>
                )}
                {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Button
                            className="btn btn-primary"
                            onClick={toggleDrawer(anchor, true, "create")}
                        >
                            {" "}
                            + Create Discount{" "}
                        </Button>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            ModalProps={{
                                BackdropProps: {
                                    style: { backgroundColor: "rgba(0, 0, 0, 0.2)" }
                                }
                            }}
                            sx={{
                                boxShadow: "none",
                                "& .MuiDrawer-paper": { boxShadow: "none" }
                            }}
                        >
                            {list(anchor)}
                        </Drawer>
                    </React.Fragment>
                ))}
            </div>

            <div className="c-hr"></div>

            <div className="l-row">
                <div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
                    <div className="filter-bar">
                        <div className="c-checkbox-radio-block">
                            <input
                                type="radio"
                                id="all"
                                name="filter"
                                value="all"
                                onChange={handleInputChange}
                                checked={filter === "all"}
                            />
                            <label htmlFor="all">All ({filterCount?.openDiscounts})</label>
                        </div>
                        <div className="c-checkbox-radio-block">
                            <input
                                type="radio"
                                id="active"
                                name="filter"
                                value="active"
                                onChange={handleInputChange}
                                checked={filter === "active"}
                            />
                            <label htmlFor="active">
                                Active ({filterCount?.paidDiscounts})
                            </label>
                        </div>
                        <div className="c-checkbox-radio-block">
                            <input
                                type="radio"
                                id="scheduled"
                                name="filter"
                                value="scheduled"
                                onChange={handleInputChange}
                                checked={filter === "scheduled"}
                            />
                            <label htmlFor="scheduled">
                                Scheduled ({filterCount?.completedDiscounts})
                            </label>
                        </div>
                        <div className="c-checkbox-radio-block">
                            <input
                                type="radio"
                                id="expired"
                                name="filter"
                                value="expired"
                                onChange={handleInputChange}
                                checked={filter === "expired"}
                            />
                            <label htmlFor="expired">
                                Expired ({filterCount?.closedDiscounts})
                            </label>
                        </div>
                    </div>
                </div>

                <div className="l-col-100">
                    <div className="c-hr u-margin-bottom-0"></div>

                    <div>
                        <div className="c-list-entry c-list-entry__header align-items-center">
                            <div className="c-list-entry__checkbox c-list-filter">
                                <form>
                                    <div className="select-all-checkbox">
                                        <input
                                            type="checkbox"
                                            id="select-all"
                                            name="select-all"
                                            onChange={handleSelectAll}
                                            checked={selectedCheckbox.length === discounts.length}
                                        />
                                        <label htmlFor="select-all"></label>
                                    </div>
                                </form>
                            </div>
                            <div className="c-list-entry__discountCode c-list-filter">
                                <button
                                    className={getSortClass("codes")}
                                    onClick={(e) => sortDiscounts(e, "codes")}
                                >
                                    Discounts code
                                </button>
                            </div>
                            <div className="c-list-entry__discountStatus c-list-filter">
                                <button
                                    className={getSortClass("status")}
                                    onClick={(e) => sortDiscounts(e, "status")}
                                >
                                    Status
                                </button>
                            </div>
                            <div className="c-list-entry__uses c-list-filter">
                                <button
                                    className={getSortClass("uses")}
                                    onClick={(e) => sortDiscounts(e, "uses")}
                                >
                                    Uses
                                </button>
                            </div>
                            <div className="c-list-entry__startDate c-list-filter">
                                <button
                                    className={getSortClass("startDate")}
                                    onClick={(e) => sortDiscounts(e, "startDate")}
                                >
                                    Start Date
                                </button>
                            </div>
                            <div className="c-list-entry__startDate c-list-filter">
                                <button
                                    className={getSortClass("endDate")}
                                    onClick={(e) => sortDiscounts(e, "endDate")}
                                >
                                    End Date
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        {discounts?.length > 0 ? (
                            discounts?.map(renderDiscounts)
                        ) : (
                            <div className="u-text-align-center u-margin-top-bottom-m u-color-red">
                                No Discounts found
                            </div>
                        )}
                    </div>

                    <div className="c-hr u-margin-bottom-0"></div>

                    <Pagination
                        pageSize={pageSize}
                        totalPages={totalPages}
                        page={page}
                        setPage={setPage}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        setPageSize={setPageSize}
                    />
                    {/* <div className="c-pagination">
                        <span>
                            Showing
                            <FormControl sx={{ minWidth: 80 }}>
                                <Select
                                    value={pageSize}
                                    sx={{
                                        height: 35,
                                        width: 75,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        "& fieldset": {
                                            borderRadius: 0,
                                        },
                                    }}
                                    onChange={handlePageSize}
                                >
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </FormControl>
                            records per page
                        </span>

                        <span className="c-pagination">
                            <span
                                as="button"
                                className="btn-next-prev"
                                onClick={handlePrvPage}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-chevron-left h-4 w-4"
                                >
                                    <path d="m15 18-6-6 6-6"></path>
                                </svg>
                            </span>

                        
                            <input
                                className="c-pagination__input"
                                value={inputValue}
                                inputMode="numeric"
                                pattern="[1-9]*"
                                type="text"
                                style={{ width: `${Math.max(33, inputValue.length * 10)}px` }}
                                onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g, ""); // Keep only numbers
                                    setInputValue(value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleGoPage();
                                    }
                                }}
                            />
                            <span className="c-pagination__text">
                                / {totalPages > 0 ? totalPages : 1}
                            </span>

                        
                            <span className="btn-next-prev" onClick={handleNextPage}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-chevron-right h-4 w-4"
                                >
                                    <path d="m9 18 6-6-6-6"></path>
                                </svg>
                            </span>
                        </span>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

Discounts.propTypes = {
    userDetails: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default withNavigate(Discounts);