import React, { useState, useEffect, useCallback, useMemo } from "react";
import _ from "lodash";
import {
  deleteAvatarsAPI,
  getUserRecordsCount,
  getUsersDrivers,
} from "../modules/Admin";
import {
  showSpinner,
  closeSpinner,
  modal,
  alert,
  confirm,
} from "../modules/Dialogs";
import {
  sessionExpired,
  serverUrl,
  userImagesFolder,
  thumbSuffix,
  defaultAvatarImage,
  imageFormat,
} from "../config/GeneralConfig";
import { toScreen } from "../modules/Routing";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import Pagination from "./Pagination";

const Mavs = ({ userDetails }) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(null);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(
    window?.history?.state?.usr?.selectedFilter ?? "active"
  );
  const [sort, setSort] = useState("");
  const [mode, setMode] = useState("list");
  const [deleteAvatars, setDeleteAvatars] = useState([]);
  const [mavs, setMavs] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState(1);
  const [filterCount, setFilterCount] = useState({
    activeUsers: null,
    preactiveUsers: null,
    blockedUsers: null,
    totalDocs: null,
  });

  const getMavs = (event) => {
    if (event) event.preventDefault();

    showSpinner();

    const queryParams = {
      filter,
      search,
      page: page,
      limit: pageSize,
      sort,
    };

    getUsersDrivers(queryParams, getMavs_done, userDetails.jwttoken);
  };

  const getMavs_done = (resStatus, responseString, resJson) => {
    closeSpinner();

    if (resStatus === 401) {
      modal(sessionExpired);
      toScreen(false, navigate, "/logout");
    } else if (resStatus === "400") {
      setMavs({});
      alert(responseString);
    } else if (resStatus === 200) {
      setTotalPages(Math.ceil(resJson?.totalRecords / pageSize));
      setDeleteAvatars([]);
      setMavs(resJson?.data);
    }
  };

  const fetchFilterCount = useCallback(() => {
    showSpinner();
    getUserRecordsCount(
      { userType: "mav" },
      (resStatus, responseString, resJson) => {
        closeSpinner();
        if (resStatus === 401) {
          modal(sessionExpired);
          toScreen(false, navigate, "/logout");
        } else if (resStatus === "400") {
          alert(responseString);
        } else if (resStatus === 200) {
          setFilterCount({
            activeUsers: resJson.activeUsers,
            preactiveUsers: resJson.preactiveUsers,
            blockedUsers: resJson.blockedUsers,
            totalDocs: resJson.totalDocs,
          });
        }
      },
      userDetails.jwttoken
    );
  }, [userDetails.jwttoken, navigate]);

  const searchMav = useCallback(() => {
    setSearch(searchValue);
    setFilter(searchValue === "" ? "active" : null);
    setPage(1);
  }, [searchValue]);

  const sortMavs = useCallback(
    (theSort) => {
      const getSortValue = (field) => {
        if (sort === `${field}=1`) return `${field}=-1`;
        return `${field}=1`;
      };

      setSearch("");
      setSort(getSortValue(theSort));
      setPage(1);
    },
    [sort]
  );

  const deleteAvatarToggle = useCallback((shortId) => {
    setDeleteAvatars((prev) => {
      if (prev.includes(shortId)) {
        return prev.filter((id) => id !== shortId);
      }
      return [...prev, shortId];
    });
  }, []);

  const deleteAvatarsHandler = useCallback(() => {
    if (deleteAvatars.length === 0) return;

    const theUser = {
      accountInfo: {
        username: userDetails.accountInfo.username,
        email: userDetails.accountInfo.email,
      },
      userType: "mav",
      deleteAvatars,
    };
    confirm(
      false,
      `Delete ${deleteAvatars.length} MAV avatars?`,
      "Cancel",
      "Delete",
      () => {
        showSpinner();
        deleteAvatarsAPI(
          theUser,
          (resStatus, responseString) => {
            closeSpinner();
            if (resStatus === 401) {
              modal(sessionExpired);
            } else if (resStatus === "400") {
              alert(responseString);
            } else if (resStatus === 200) {
              setDeleteAvatars([]);
              getMavs();
              modal("Avatars deleted successfully", "Good job!");
            }
          },
          userDetails.jwttoken
        );
      }
    );
  }, [deleteAvatars, navigate, userDetails.jwttoken, getMavs]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    if (name === "search") {
      setSearchValue(inputValue);
    } else if (name === "filter") {
      setSearch("");
      setSearchValue(null);
      setFilter(inputValue);
      setPage(1);
    } else if (name === "mode") {
      setMode(inputValue);
    }
  };

  const getSortClass = useCallback(
    (key) => {
      if (key === "location")
        return "c-list-filter__button--faded cursor-default";

      if (sort === `${key}=1`)
        return "c-list-filter__button c-list-filter__button--icon-up";
      if (sort === `${key}=-1`)
        return "c-list-filter__button c-list-filter__button--icon-down";
      return "c-list-filter__button c-list-filter__button--faded";
    },
    [sort]
  );

  const getAvatarSrc = useCallback((mav) => {
    if (mav.accountInfo.avatar && mav.accountInfo.avatar !== "") {
      return `${serverUrl}${userImagesFolder}/${mav.shortId}/${mav.accountInfo.avatar}${thumbSuffix}${imageFormat}`;
    }
    return `../img/${defaultAvatarImage}${imageFormat}`;
  }, []);

  const renderMavs = useCallback(
    (mav, key) => {
      if (mode === "list") {
        return (
          <div className="c-list-entry" key={key}>
            <div className="c-list-entry__id">
              <span className="u-before-icon-calendar2">
                &nbsp;&nbsp;
                {moment(mav?.createdAt)
                  ?.tz("Europe/London")
                  ?.format("DD MMM YYYY")}
              </span>
              <br />
              <span className="u-before-icon-clock">
                &nbsp;&nbsp;
                {moment(mav?.createdAt)?.tz("Europe/London")?.format("hh:mm ")}
              </span>
            </div>
            <div className="c-list-entry__mavname">
              <b className="text-capitalize">{mav?.accountInfo?.firstname}</b>
            </div>
            {/* <div className="c-list-entry__tel">
              {mav.accountInfo?.telephone}
            </div>
            <div className="c-list-entry__mavlocation">
              <span className="u-before-icon-location">&nbsp;&nbsp;
                {mav?.accountInfo?.location}
              </span>
            </div> */}
            <div className="c-list-entry__mavquotes">
              <b>{mav?.totalQuotes ?? 0}</b>
            </div>
            <div className="c-list-entry__mavjobs">
              <b>{mav?.completedJobs ?? 0}</b>
            </div>
            <div className="c-list-entry__reviews">
              <b>{mav?.reviews ?? 0}</b>
            </div>
            <div className="c-list-entry__refunds">
              <b>{mav?.refunds ?? 0}</b>
            </div>

            <button
              type="button"
              className="c-btn c-btn--teal c-btn--small"
              onClick={(e) => toScreen(e, navigate, `/mav/${mav.shortId}`)}
            >
              View
            </button>
            <button
              type="button"
              className="c-btn c-btn--green c-btn--small me-0"
              onClick={(e) =>
                toScreen(e, navigate, `/message/${mav.shortId}`, false, {
                  receiverId: mav?._id,
                  receiverShortId: mav?.shortId,
                  receiverType: mav?.userType,
                  userShortId1: "admin",
                  userShortId2: mav?.shortId,
                  username2: mav?.accountInfo?.username,
                  selectedPage: page,
                  selectedFilter: filter,
                  previousPath: window.location.pathname,
                })
              }
            >
              Contact MAV
            </button>
          </div>
        );
      } else if (mode === "avatars") {
        const isSelected = deleteAvatars.includes(mav.shortId);
        return (
          <div
            className={`c-avatar-grid__avatar ${isSelected ? "c-avatar-grid__avatar--selected" : ""
              }`}
            key={key}
            onClick={() => deleteAvatarToggle(mav.shortId)}
          >
            <img
              src={getAvatarSrc(mav)}
              alt="avatar"
              className="c-avatar__pic u-cursor-pointer"
              onError={(e) => {
                e.target.src = defaultAvatarImage;
              }}
            />
            <div className="c-avatar-grid__icon u-before-icon-cancel"></div>
          </div>
        );
      }
      return null;
    },
    [
      mode,
      deleteAvatars,
      page,
      filter,
      navigate,
      deleteAvatarToggle,
      getAvatarSrc,
    ]
  );

  // Memoized list header
  const listHeader = useMemo(
    () => (
      <div className="c-list-entry c-list-entry__header">
        {[
          { id: "createdAt", name: "Cr. Date", className: "c-list-entry__id" },
          { id: "name", name: "Name", className: "c-list-entry__mavname" },
          // { id: "tel", name: "Tel", className: "c-list-entry__tel" },
          // {
          //   id: "location",
          //   name: "Location",
          //   className: "c-list-entry__mavlocation",
          // },
          {
            id: "quotes",
            name: "Quotes",
            className: "c-list-entry__mavquotes",
          },
          { id: "jobs", name: "Jobs", className: "c-list-entry__mavjobs" },
          {
            id: "reviews",
            name: "Reviews",
            className: "c-list-entry__reviews",
          },
          {
            id: "refunds",
            name: "Refunds",
            className: "c-list-entry__refunds",
          },
        ]?.map((col) => (
          <div key={col.id} className={`${col.className} c-list-filter`}>
            <button
              className={getSortClass(col.id)}
              onClick={() => col.id !== "location" && sortMavs(col.id)}
            >
              {col.name}
            </button>
          </div>
        ))}
      </div>
    ),
    [getSortClass, sortMavs]
  );

  // Fetch data when relevant state changes
  useEffect(() => {
    getMavs();
  }, [page, pageSize, filter, sort, search]);

  // Initial data fetch
  useEffect(() => {
    fetchFilterCount();
  }, [fetchFilterCount]);

  useEffect(() => {
    setInputValue(page);
  }, [page]);
  return (
    <div className="l-base-layout__content">
      <h1>Admin / MAVs</h1>
      <div className="c-hr"></div>

      <div className="c-subheader-info-panel">
        <div className="c-subheader-info-panel__primary">
          Total: {filterCount?.totalDocs}
        </div>
        <div className="c-subheader-info-panel__search">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              searchMav();
            }}
          >
            <input
              type="text"
              value={searchValue || ""}
              onChange={handleInputChange}
              name="search"
              placeholder=""
              className="c-subheader-info-panel__input"
            />
            <button
              type="button"
              className="c-btn-outline c-btn-outline--white c-btn-outline--small"
              onClick={() => searchValue !== null && searchMav()}
            >
              Search
            </button>
          </form>
        </div>
      </div>

      <div className="c-hr"></div>

      <div className="l-row">
        <div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
          <div className="filter-bar">
            {[
              {
                id: "preactivation",
                label: `Pre Activation (${filterCount?.preactiveUsers || 0})`,
              },
              {
                id: "active",
                label: `Active (${filterCount?.activeUsers || 0})`,
              },
              {
                id: "blocked",
                label: `Blocked (${filterCount?.blockedUsers || 0})`,
              },
            ].map((filterOption) => (
              <div className="c-checkbox-radio-block" key={filterOption.id}>
                <input
                  type="radio"
                  id={filterOption.id}
                  name="filter"
                  value={filterOption.id}
                  onChange={handleInputChange}
                  checked={filter === filterOption.id}
                />
                <label htmlFor={filterOption.id}>{filterOption.label}</label>
              </div>
            ))}

            {["list", "avatars"].map((modeOption) => (
              <div
                className="c-checkbox-radio-block u-color-grey-mid-light-light-bg"
                key={modeOption}
              >
                <input
                  type="radio"
                  id={modeOption}
                  name="mode"
                  value={modeOption}
                  onChange={handleInputChange}
                  checked={mode === modeOption}
                />
                <label htmlFor={modeOption}>
                  {modeOption.charAt(0).toUpperCase() + modeOption.slice(1)}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="l-col-100">
          <div className="c-hr u-margin-bottom-0"></div>

          <div>{listHeader}</div>

          {deleteAvatars.length > 0 && (
            <div className="u-text-align-center u-margin-top-bottom-m">
              <button
                className="c-btn c-btn--small u-margin-bottom-xs c-btn--grey"
                onClick={() => setDeleteAvatars([])}
              >
                Deselect Avatars
              </button>
              <button
                className="c-btn c-btn--small u-margin-bottom-xs c-btn--red"
                onClick={deleteAvatarsHandler}
              >
                Delete Avatars
              </button>
            </div>
          )}

          <div
            className={
              mode === "avatars" && mavs.length > 0 ? "c-avatar-grid" : ""
            }
          >
            {mavs.length > 0 ? (
              mavs.map(renderMavs)
            ) : (
              <div className="u-text-align-center u-margin-top-bottom-m u-color-red">
                No records found
              </div>
            )}
          </div>

          <div className="c-hr u-margin-bottom-0 u-clear-both"></div>
          <Pagination
            pageSize={pageSize}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
};

Mavs.propTypes = {
  userDetails: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default Mavs;
