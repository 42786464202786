import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreateDiscount = ({ view, discount, onClose }) => {
    const isEdit = view === "edit";
    const [discountAmount, setDiscountAmount] = useState("");
    const [discountCode, setDiscountCode] = useState("");
    const [minJobValueRequired, setMinJobValueRequired] = useState(false);
    const [minJobValue, setMinJobValue] = useState("");
    const [appliesTo, setAppliesTo] = useState("allJobs");
    const [specificJobsExpanded, setSpecificJobsExpanded] = useState(false);
    const [specificJobs, setSpecificJobs] = useState({
        sendItems: false,
        houseMoves: false,
        disposeOfItems: false,
        wasteRemoval: false,
        houseClearance: false,
    });
    const [usageLimitsExpanded, setUsageLimitsExpanded] = useState(false);
    const [totalUsageLimit, setTotalUsageLimit] = useState("");
    const [endDateExpanded, setEndDateExpanded] = useState(false);
    const [customerEligibilityOption, setCustomerEligibilityOption] =
        useState("everyone");
    const [limitPerCustomer, setLimitPerCustomer] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        if (isEdit && discount) {
            setDiscountCode(discount.codes || "");
            setDiscountAmount(discount.discountAmount || "10%");

            if (discount.uses) {
                const [used, total] = discount.uses.split("/");
                setTotalUsageLimit(total || "");
                setUsageLimitsExpanded(!!total);
            }

            setStartDate(discount.startDate || "");
            setEndDate(discount.endDate || "");
            // setEndDateExpanded(!!discount.endDate);
        }
    }, [isEdit, discount]);

    // Toggle specific job selection
    const handleSpecificJobToggle = (job) => {
        setSpecificJobs((prev) => ({
            ...prev,
            [job]: !prev[job],
        }));
    };

    const handleAppliesToChange = (option) => {
        if (option === "allJobs") {
            setAppliesTo("allJobs");
            setSpecificJobsExpanded(false);
        } else {
            setAppliesTo("specificJobs");
            setSpecificJobsExpanded(true);
        }
    };

    const handleCustomerEligibilityToggle = (option) => {
        setCustomerEligibilityOption(option);
    };

    const handleLimitPerCustomerToggle = () => {
        setLimitPerCustomer((prev) => !prev);
    };

    return (
        <div className="right-modal discount-modal">
            <div className="modal-header">
                <div className="modal-title">
                    <h2>
                        <strong>{isEdit ? "Edit Details" : "Create Discount Code"}</strong>
                    </h2>
                    <button className="modal-close-btn" onClick={onClose}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="lucide lucide-x h-5 w-5"
                        >
                            <path d="M18 6 6 18"></path>
                            <path d="m6 6 12 12"></path>
                        </svg>
                    </button>
                </div>
            </div>

            <div className="modal-body">
                <div className="form-group">
                    <label>
                        <strong>Discount Code</strong>
                    </label>
                    <input
                        type="text"
                        placeholder="e.g., SAVE10"
                        value={discountCode}
                        onChange={(e) => setDiscountCode(e.target.value)}
                    />
                    <p className="coupon-description">
                        Enter a unique alphanumeric code for this discount.
                    </p>
                </div>

                <div className="form-group">
                    <label>
                        <strong>Discount Amount</strong>
                    </label>
                    <select
                        value={discountAmount}
                        onChange={(e) => setDiscountAmount(e.target.value)}
                    >
                        <option>5%</option>
                        <option>10%</option>
                        <option>15%</option>
                        <option>20%</option>
                    </select>
                </div>

                <div className="form-group">
                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            id="discount"
                            checked={minJobValueRequired}
                            onChange={() => setMinJobValueRequired((prev) => !prev)}
                        />
                        This discount requires a minimum job value
                        <span htmlFor="discount" className="checkbox"></span>
                    </label>

                    {minJobValueRequired && (
                        <div className="min-job-value">
                            <label>Minimum Job Value (£)</label>
                            <input
                                type="number"
                                placeholder=""
                                value={minJobValue}
                                onChange={(e) => setMinJobValue(e.target.value)}
                            />
                        </div>
                    )}
                </div>
                <div className="modal-checkbox-block">
                    <h3>
                        <strong> Applies To</strong>
                    </h3>
                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            id="allJobs"
                            checked={appliesTo === "allJobs"}
                            onChange={() => handleAppliesToChange("allJobs")}
                        />
                        <strong>All Jobs</strong>
                        <span className="checkbox"></span>
                    </label>

                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            id="specificJobs"
                            checked={appliesTo === "specificJobs"}
                            onChange={() => handleAppliesToChange("specificJobs")}
                        />
                        <strong> Specific Jobs</strong>
                        <span className="checkbox"></span>
                    </label>

                    {specificJobsExpanded && (
                        <div className="specific-jobs-block">
                            {[
                                { id: "sendItems", label: "Send items" },
                                { id: "houseMoves", label: "House Move" },
                                { id: "disposeOfItems", label: "Dispose of items" },
                                { id: "wasteRemoval", label: "Waste removal" },
                                { id: "houseClearance", label: "House clearance" },
                            ].map((job) => (
                                <label key={job.id} className="custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id={job.id}
                                        checked={specificJobs[job.id]}
                                        onChange={() => handleSpecificJobToggle(job.id)}
                                    />
                                    {job.label}
                                    <span className="checkbox"></span>
                                </label>
                            ))}
                        </div>
                    )}
                </div>

                <div className="modal-checkbox-block">
                    <h3>
                        <strong>Customer Eligibility</strong>
                    </h3>
                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            id="everyone"
                            name="checkbox"
                            checked={customerEligibilityOption === "everyone"}
                            onChange={() => handleCustomerEligibilityToggle("everyone")}
                        />{" "}
                        <strong>Everyone</strong>
                        <span className="checkbox" htmlFor="everyone"></span>
                    </label>
                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            id="newCustomersOnly"
                            name="checkbox"
                            checked={customerEligibilityOption === "newCustomersOnly"}
                            onChange={() =>
                                handleCustomerEligibilityToggle("newCustomersOnly")
                            }
                        />
                        <strong> New Customers Only</strong>
                        <span className="checkbox" htmlFor="newCustomersOnly"></span>
                    </label>
                </div>

                <div className="modal-checkbox-block">
                    <h3>
                        <strong>Usage Limits</strong>
                    </h3>
                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            id="limitTotal"
                            name="checkbox"
                            checked={usageLimitsExpanded}
                            onChange={() => setUsageLimitsExpanded(!usageLimitsExpanded)}
                        />
                        <strong>
                            {" "}
                            Limit number of times this discount can be used in total
                        </strong>
                        <span className="checkbox" htmlFor="limitTotal"></span>
                    </label>
                    {usageLimitsExpanded && (
                        <div className="form-group">
                            <div className="min-job-value">
                                <label>
                                    <strong>Total Usage Limit</strong>
                                </label>
                                <input
                                    type="number"
                                    placeholder=""
                                    value={totalUsageLimit}
                                    onChange={(e) => setTotalUsageLimit(e.target.value)}
                                />
                            </div>
                        </div>
                    )}
                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            id="limitPerCustomer"
                            name="checkbox"
                            checked={limitPerCustomer}
                            onChange={handleLimitPerCustomerToggle}
                        />{" "}
                        <strong>Limit to one time per customer</strong>
                        <span className="checkbox" htmlFor="limitPerCustomer"></span>
                    </label>
                </div>

                <div className="modal-checkbox-block">
                    <h3>
                        <strong>Active Dates</strong>
                    </h3>
                    <div className="form-group">
                        <label>
                            <strong>Start Date & Time</strong>
                        </label>

                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            timeInputLabel="Time:"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput
                        />
                    </div>
                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            id="setEndDate"
                            name="checkbox"
                            checked={endDateExpanded}
                            onChange={() => setEndDateExpanded(!endDateExpanded)}
                        />{" "}
                        <strong>Set End Date</strong>
                        <span className="checkbox"></span>
                    </label>
                    {endDateExpanded && (
                        <div>
                            <div className="form-group ml-32 mt-16">
                                <label>
                                    <strong>End Date & Time</strong>
                                </label>
                                {
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        timeInputLabel="Time:"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeInput
                                        placeholderText="Pick a date"
                                    />
                                }
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="modal-footer">
                <div className="d-flex align-items-center g-12">
                    <button className="btn btn-primary">
                        {isEdit ? "Update Discount" : "Create Discount"}
                    </button>
                    <button className="btn btn-outline-primary" onClick={onClose}>
                        Cancel
                    </button>
                </div>
                {isEdit && (
                    <button className="btn btn-danger icon-with-btn">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path d="M3 6h18"></path>
                            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                            <line x1="10" x2="10" y1="11" y2="17"></line>
                            <line x1="14" x2="14" y1="11" y2="17"></line>
                        </svg>
                        Delete
                    </button>
                )}
            </div>
        </div>
    );
};

export default CreateDiscount;
