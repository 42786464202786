import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { getUserRecordsCount, getUsersClients } from "../modules/Admin";
import { showSpinner, closeSpinner, modal, alert } from "../modules/Dialogs";
import { sessionExpired } from "../config/GeneralConfig";
import { toScreen } from "../modules/Routing";
import Pagination from "./Pagination";

const Clients = ({ userDetails }) => {
  const navigate = useNavigate();
  const jwtToken = userDetails.jwttoken;

  const [searchValue, setSearchValue] = useState(null);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState(1);
  const [filter, setFilter] = useState(
    window.history.state?.usr?.selectedFilter ?? "active"
  );
  const [sort, setSort] = useState("");
  const [clients, setClients] = useState([]);
  const [filterCount, setFilterCount] = useState({
    activeUsers: 0,
    preactiveUsers: 0,
    blockedUsers: 0,
    totalDocs: 0,
  });

  useEffect(() => {
    setInputValue(page);
  }, [page]);

  useEffect(() => {
    getClients();
  }, [filter, page, pageSize, sort, search]);

  useEffect(() => {
    fetchFilterCount();
  }, []);

  const getClients = (event) => {
    if (event) event.preventDefault();

    showSpinner();

    const queryParams = {
      filter,
      search,
      page: page,
      limit: pageSize,
      sort,
    };
    getUsersClients(queryParams, getClients_done, jwtToken);
  };

  const getClients_done = (resStatus, responseString, resJson) => {
    closeSpinner();

    if (resStatus === 401) {
      modal(sessionExpired);
      toScreen(false, navigate, "/logout");
    } else if (resStatus === "400") {
      setClients([]);
      alert(responseString);
    } else if (resStatus === 200) {
      setTotalPages(Math.ceil(resJson?.totalRecords / pageSize));
      setClients(resJson?.data || []);
    }
  };

  // Fetch filter counts
  const fetchFilterCount = () => {
    showSpinner();
    getUserRecordsCount({ userType: "user" }, handleFilterCount_done, jwtToken);
  };

  const handleFilterCount_done = (resStatus, responseString, resJson) => {
    closeSpinner();

    if (resStatus === 401) {
      modal(sessionExpired);
      toScreen(false, navigate, "/logout");
    } else if (resStatus === "400") {
      alert(responseString);
    } else if (resStatus === 200) {
      setFilterCount({
        activeUsers: resJson?.activeUsers,
        preactiveUsers: resJson?.preactiveUsers,
        blockedUsers: resJson?.blockedUsers,
        totalDocs: resJson?.totalDocs,
      });
    }
  };

  // Search handler
  const searchClient = () => {
    setSearch(searchValue);
    setFilter(searchValue === "" ? "active" : null);
    setPage(1);
  };

  // Sort handler
  const sortClients = (event, field) => {
    if (event) event.preventDefault();

    const sortFields = {
      name: { asc: "name=1", desc: "name=-1" },
      email: { asc: "email=1", desc: "email=-1" },
      tel: { asc: "tel=1", desc: "tel=-1" },
      location: { asc: "location=1", desc: "location=-1" },
      completedJobCount: {
        asc: "completedJobCount=1",
        desc: "completedJobCount=-1",
      },
      totalJobCount: { asc: "totalJobCount=1", desc: "totalJobCount=-1" },
      reviews: { asc: "reviews=1", desc: "reviews=-1" },
    };

    const currentSort = sortFields[field];
    if (!currentSort) return;

    const newSort =
      sort === currentSort.asc ? currentSort.desc : currentSort.asc;

    setSearch("");
    setSort(newSort);
    setPage(1);
  };

  // Input handler
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputCheckValue = type === "checkbox" ? checked : value;

    if (name === "search") {
      setSearchValue(inputCheckValue);
    } else if (name === "filter") {
      setSearch("");
      setSearchValue(null);
      setFilter(inputCheckValue);
      setPage(1);
    }
  };

  const getSortClass = (key) => {
    if (sort === `${key}=1`) {
      return "c-list-filter__button c-list-filter__button--icon-up";
    } else if (sort === `${key}=-1`) {
      return "c-list-filter__button c-list-filter__button--icon-down";
    }
    return "c-list-filter__button c-list-filter__button--faded";
  };

  const renderClients = (client, key) => (
    <div className="c-list-entry" key={key}>
      <div className="c-list-entry__clientname">
        <b className="text-capitalize">{client?.accountInfo?.firstname}</b>
      </div>
      <div className="c-list-entry__tel">{client.accountInfo?.telephone}</div>
      <div className="c-list-entry__email">
        <a href={`mailto:${client.accountInfo?.email}`}>
          {client.accountInfo?.email}
        </a>
      </div>
      <div className="c-list-entry__clientjobs">
        <b>{client?.totalJobCount ?? 0}</b>
      </div>
      <div className="c-list-entry__clientcompleted">
        <b>{client?.completedJobs ?? 0}</b>
      </div>
      <button
        type="button"
        className="c-btn c-btn--teal c-btn--small"
        onClick={(e) => toScreen(e, navigate, `/client/${client?.shortId}`)}
      >
        View
      </button>
      <button
        type="button"
        className="c-btn c-btn--green c-btn--small me-0"
        onClick={(e) =>
          toScreen(e, navigate, `/message/${client.shortId}`, false, {
            receiverId: client?._id,
            receiverShortId: client?.shortId,
            receiverType: client?.userType,
            userShortId1: "admin",
            userShortId2: client?.shortId,
            username2: client?.accountInfo?.username,
            selectedPage: page,
            selectedFilter: filter,
            previousPath: window.location.pathname,
          })
        }
      >
        Contact Client
      </button>
    </div>
  );

  const SortButton = useCallback(
    ({ field, label }) => (
      <button
        className={getSortClass(field)}
        onClick={(e) => sortClients(e, field)}
      >
        {label}
      </button>
    ),
    [getSortClass, sortClients]
  );

  // Create table header once
  const tableHeader = useMemo(
    () => (
      <div className="c-list-entry c-list-entry__header">
        <div className="c-list-entry__clientname c-list-filter">
          <SortButton field="name" label="Name" />
        </div>
        <div className="c-list-entry__tel c-list-filter">
          <SortButton field="tel" label="Tel" />
        </div>
        <div className="c-list-entry__email c-list-filter">
          <SortButton field="email" label="Email" />
        </div>
        <div className="c-list-entry__clientjobs c-list-filter">
          <SortButton field="totalJobCount" label="Jobs" />
        </div>
        <div className="c-list-entry__clientcompleted c-list-filter">
          <SortButton field="completedJobCount" label="Completed" />
        </div>
      </div>
    ),
    [SortButton]
  );

  return (
    <div className="l-base-layout__content">
      <h1>Admin / Clients</h1>
      <div className="c-hr"></div>

      <div className="c-subheader-info-panel">
        <div className="c-subheader-info-panel__primary">
          Total: {filterCount?.totalDocs}
        </div>
        <div className="c-subheader-info-panel__search">
          <form>
            <input
              type="text"
              value={searchValue || ""}
              onChange={handleInputChange}
              name="search"
              placeholder=""
              className="c-subheader-info-panel__input"
            />
            <button
              type="button"
              className="c-btn-outline c-btn-outline--white c-btn-outline--small"
              onClick={() => searchValue !== null && searchClient()}
              disabled={searchValue === null}
            >
              Search
            </button>
          </form>
        </div>
      </div>

      <div className="c-hr"></div>

      <div className="l-row">
        <div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
          <div className="filter-bar">
            {[
              // { id: "preactivation", label: "Pre Activation", count: filterCount?.preactiveUsers },
              {
                id: "active",
                label: "Active",
                count: filterCount?.activeUsers,
              },
              {
                id: "blocked",
                label: "Blocked",
                count: filterCount?.blockedUsers,
              },
            ]?.map(({ id, label, count }) => (
              <div className="c-checkbox-radio-block" key={id}>
                <input
                  type="radio"
                  id={id}
                  name="filter"
                  value={id}
                  onChange={handleInputChange}
                  checked={filter === id}
                />
                <label htmlFor={id}>
                  {label} ({count})
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="l-col-100">
          <div className="c-hr u-margin-bottom-0"></div>

          <div>{tableHeader}</div>

          <div>
            {clients?.length > 0 ? (
              clients?.map(renderClients)
            ) : (
              <div className="u-text-align-center u-margin-top-bottom-m u-color-red">
                No clients found
              </div>
            )}
          </div>

          <div className="c-hr u-margin-bottom-0 u-clear-both"></div>
          <Pagination
            pageSize={pageSize}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
};

Clients.propTypes = {
  userDetails: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default Clients;
