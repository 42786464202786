import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
    getReferralsRecordsCount,
    getretrieveReferrals,
} from "../modules/JobAndQuoteManager";
import { showSpinner, closeSpinner, modal, alert } from "../modules/Dialogs";
import { sessionExpired } from "../config/GeneralConfig";
import PropTypes from "prop-types";
import withNavigate from "./withNavigate";
import { FormControl, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { toScreen } from "../modules/Routing";
import Pagination from "./Pagination";

const Referrals = ({ userDetails, navigate }) => {
    // State for drawer
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedReferral, setSelectedReferral] = useState(null);
    const drawerRef = useRef(null);

    const [searchValue, setSearchValue] = useState(null);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState(
        window.history.state?.usr?.selectedFilter ?? "all"
    );
    const [pageSize, setPageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [inputValue, setInputValue] = useState(1); // Separate state for input

    const [sort, setSort] = useState("");
    const [referrals, setReferrals] = useState([
        {
            _id: "1",
            firstName: "John",
            lastName: "Doe",
            userName: "Johndoe",
            id: "U001",
            referrals: 5,
            shortId: "R78M",
        },
        {
            _id: "2",
            firstName: "Jane",
            lastName: "Smith",
            userName: "Janesmith",
            id: "U002",
            referrals: 2,
            shortId: "X90B",
        },
        {
            _id: "3",
            firstName: "Alice",
            lastName: "Johnson",
            userName: "Alicej",
            id: "U003",
            referrals: 8,
            shortId: "K22P",
        },
        {
            _id: "4",
            firstName: "Bob",
            lastName: "Brown",
            userName: "Bobb",
            id: "U004",
            referrals: 3,
            shortId: "T65D",
        },
        {
            _id: "5",
            firstName: "Charlie",
            lastName: "Davis",
            userName: "Charlied",
            id: "U005",
            referrals: 6,
            shortId: "P88Q",
        },
        {
            _id: "6",
            firstName: "David",
            lastName: "Evans",
            userName: "Davide",
            id: "U006",
            referrals: 1,
            shortId: "L77C",
        },
        {
            _id: "7",
            firstName: "Emily",
            lastName: "White",
            userName: "Emilyw",
            id: "U007",
            referrals: 4,
            shortId: "H56M",
        },
        {
            _id: "8",
            firstName: "Frank",
            lastName: "Hall",
            userName: "Frankh",
            id: "U008",
            referrals: 7,
            shortId: "M33Z",
        },
        {
            _id: "9",
            firstName: "Grace",
            lastName: "Lee",
            userName: "Gracel",
            id: "U009",
            referrals: 9,
            shortId: "Q99X",
        },
        {
            _id: "10",
            firstName: "Henry",
            lastName: "Scott",
            userName: "Henrys",
            id: "U010",
            referrals: 10,
            shortId: "V11B",
        },
    ]);

    // Simplified drawer open/close handler
    const toggleDrawer = (open, referral = null) => (event) => {
        if (
            event?.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerOpen(open);
        if (referral) {
            setSelectedReferral(referral);
        }
    };

    useEffect(() => {
        setInputValue(page);
    }, [page]);

    const searchJob = () => {
        setSearch(searchValue);
        setFilter(searchValue === "" ? "all" : null);
        setPage(1);
    };

    const sortReferrals = (event, field) => {
        if (event) event.preventDefault();

        const sortFields = {
            name: { asc: "name=1", desc: "name=-1" },
            referrals: { asc: "referrals=1", desc: "referrals=-1" },
            startDate: { asc: "startDate=1", desc: "startDate=-1" },
            uses: { asc: "uses=1", desc: "uses=-1" },
            endDate: { asc: "endDate=1", desc: "endDate=-1" },
        };

        const currentSort = sortFields[field];
        if (!currentSort) return;

        const newSort =
            sort === currentSort.asc ? currentSort.desc : currentSort.asc;

        setSearch("");
        setSort(newSort);
        setPage(1);
    };

    // Render a single referral item
    const renderReferrals = (referral, index) => {
        return (
            <div className={`c-list-entry align-items-center`} key={index}>
                <div className="c-list-entry__referralClientName">
                    <b>{referral?.userName}</b>
                    <p className="mb-0">{`${referral?.firstName} - ${referral?.shortId}`}</p>
                </div>
                <div className="c-list-entry__jobdate">{referral?.referrals}</div>
                <Button
                    className="btn btn-primary btn-sm"
                    onClick={toggleDrawer(true, referral)}
                >
                    Details
                </Button>
            </div>
        );
    };

    // Drawer content
    const drawerContent = () => (
        <Box
            sx={{ width: 600 }}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
        >
            <div ref={drawerRef} className="right-modal">
                <div className="modal-header">
                    <div className="modal-title">
                        <h2>Client Details</h2>
                        <button
                            className="modal-close-btn"
                            onClick={toggleDrawer(false)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-x h-5 w-5"
                            >
                                <path d="M18 6 6 18"></path>
                                <path d="m6 6 12 12"></path>
                            </svg>
                        </button>
                    </div>
                </div>
                {selectedReferral && (
                    <div className="modal-body">
                        <div className="client-main-info">
                            <div>
                                <h3>
                                    {selectedReferral.firstName} {selectedReferral.lastName}
                                </h3>
                                <p className="client-id">
                                    {selectedReferral.userName}-{selectedReferral.shortId}
                                </p>
                            </div>
                            <button
                                className="c-btn c-btn--black c-btn--small"
                                onClick={(e) =>
                                    toScreen(e, navigate, "/client/" + selectedReferral?._id)
                                }
                            >
                                <span className="u-before-icon-avatar-fill text-capitalize"></span>
                                Client Details
                            </button>
                        </div>

                        <div className="referral-summary">
                            <div className="referral-box">
                                <p className="referral-title mb-0">Total Referrals</p>
                                <p className="referral-count mb-0">{selectedReferral.referrals} </p>
                            </div>
                        </div>

                        <div className="referred-clients-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Referred Clients</th>
                                        <th>Signed Up</th>
                                        <th>Posted Jobs</th>
                                        <th>Booked Jobs</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[
                                        {
                                            name: "Grace Lee",
                                            date: "30 May 2023",
                                            posted: 1,
                                            booked: 1,
                                        },
                                        {
                                            name: "Henry Clark",
                                            date: "15 Jun 2023",
                                            posted: 2,
                                            booked: 2,
                                        },
                                        {
                                            name: "Ivy Martinez",
                                            date: "5 Jul 2023",
                                            posted: 3,
                                            booked: 1,
                                        },
                                        {
                                            name: "Jack Robinson",
                                            date: "10 Aug 2023",
                                            posted: 0,
                                            booked: 0,
                                        },
                                    ]?.map((client, index) => (
                                        <tr key={index}>
                                            <td className="client-name-cell">
                                                <a href="/client/id">{client?.name}</a>
                                            </td>
                                            <td>{client?.date}</td>
                                            <td>{client?.posted}</td>
                                            <td>{client?.booked}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </Box>
    );

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const inputValue = type === "checkbox" ? checked : value;

        if (name === "search") {
            setSearchValue(inputValue);
        } else if (name === "filter") {
            setSearch("");
            setSearchValue(null);
            setFilter(inputValue);
            setPage(1);
        }
    };

    const getSortClass = (key) => {
        if (sort === `${key}=1`) {
            return "c-list-filter__button c-list-filter__button--icon-up";
        } else if (sort === `${key}=-1`) {
            return "c-list-filter__button c-list-filter__button--icon-down";
        }
        return "c-list-filter__button c-list-filter__button--faded";
    };

    // useEffect(() => {
    //     getReferrals();
    // }, [filter, page, sort, pageSize]);

    return (
        <div className="l-base-layout__content">
            <h1>Admin / Referrals</h1>
            <div className="c-hr"></div>

            <div className="c-subheader-info-panel">
                <div className="c-subheader-info-panel__search">
                    <form className="d-flex align-items-center">
                        <div className="search-input-block">
                            <input
                                type="text"
                                value={searchValue || ""}
                                onChange={handleInputChange}
                                name="search"
                                placeholder="Search here..."
                                className="c-subheader-info-panel__input"
                            />
                            {searchValue && (
                                <svg
                                    onClick={() => setSearchValue("")}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="input-close-icon"
                                >
                                    <path d="M18 6 6 18"></path>
                                    <path d="m6 6 12 12"></path>
                                </svg>
                            )}
                        </div>

                        <button
                            type="button"
                            className="c-btn-outline c-btn-outline--white c-btn-outline--small"
                            onClick={searchJob}
                        >
                            Search
                        </button>
                    </form>
                </div>
            </div>

            <div className="c-hr"></div>
            <div className="l-row">
                <div className="l-col-100">
                    <div className="c-hr u-margin-bottom-0"></div>

                    <div>
                        <div className="c-list-entry c-list-entry__header ">
                            <div className="c-list-entry__referralClientName c-list-filter">
                                <button
                                    className={getSortClass("name")}
                                    onClick={(e) => sortReferrals(e, "name")}
                                >
                                    Client name
                                </button>
                            </div>
                            <div className="c-list-entry__jobdate c-list-filter">
                                <button
                                    className={getSortClass("referrals")}
                                    onClick={(e) => sortReferrals(e, "referrals")}
                                >
                                    Referrals
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        {referrals?.length > 0 ? (
                            referrals?.map(renderReferrals)
                        ) : (
                            <div className="u-text-align-center u-margin-top-bottom-m u-color-red">
                                No Referrals found
                            </div>
                        )}
                    </div>

                    {/* Drawer */}
                    <Drawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                        ModalProps={{
                            BackdropProps: {
                                style: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                            },
                        }}
                        sx={{
                            boxShadow: "none",
                            "& .MuiDrawer-paper": { boxShadow: "none" },
                        }}
                    >
                        {drawerContent()}
                    </Drawer>

                    <div className="c-hr u-margin-bottom-0"></div>

                    <Pagination
                        pageSize={pageSize}
                        totalPages={totalPages}
                        page={page}
                        setPage={setPage}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        setPageSize={setPageSize}
                    />
                </div>
            </div>
        </div>
    );
};

Referrals.propTypes = {
    userDetails: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default withNavigate(Referrals);
