import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";

const Pagination = ({
    pageSize,
    totalPages,
    page,
    inputValue,
    setInputValue,
    setPageSize,
    setPage,
}) => {
    const handleGoPage = () => {
        const newPage = Math.min(
            Math.max(Number(inputValue, 10) || 1, 1),
            totalPages
        );
        setPage(newPage);
    };

    const handlePageSize = (event) => {
        setPageSize(event.target.value);
        setPage(1);
        setInputValue(1);
    };

    const handlePrvPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    return (
        <div>
            <div className="c-hr u-margin-bottom-0"></div>
            <div className="c-pagination">
                <span>
                    Showing
                    <FormControl sx={{ minWidth: 80 }}>
                        <Select
                            value={pageSize}
                            sx={{
                                height: 35,
                                width: 75,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                "& fieldset": {
                                    borderRadius: 0,
                                },
                            }}
                            onChange={handlePageSize}
                        >
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                    records per page
                </span>

                <span className="c-pagination">
                    <span as="button" className="btn-next-prev" onClick={handlePrvPage}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="lucide lucide-chevron-left h-4 w-4"
                        >
                            <path d="m15 18-6-6 6-6"></path>
                        </svg>
                    </span>

                    {/* Page Input */}
                    <input
                        className="c-pagination__input"
                        value={inputValue}
                        inputMode="numeric"
                        pattern="[1-9]*"
                        type="text"
                        style={{ width: `${Math.max(33, inputValue.length * 10)}px` }}
                        onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, ""); // Keep only numbers
                            setInputValue(value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleGoPage();
                            }
                        }}
                    />
                    <span className="c-pagination__text">
                        / {totalPages > 0 ? totalPages : 1}
                    </span>

                    {/* Next Button */}
                    <span className="btn-next-prev" onClick={handleNextPage}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="lucide lucide-chevron-right h-4 w-4"
                        >
                            <path d="m9 18 6-6-6-6"></path>
                        </svg>
                    </span>
                </span>
            </div>
        </div>
    );
};

export default Pagination;
