import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import {
  getJobsRecordsCount,
  getretrieveJobs,
} from "../modules/JobAndQuoteManager";
import { showSpinner, closeSpinner, modal, alert } from "../modules/Dialogs";
import { sessionExpired } from "../config/GeneralConfig";
import { toScreen } from "../modules/Routing";
import PropTypes from "prop-types";
import withNavigate from "./withNavigate";
import moment from "moment-timezone";
import Pagination from "./Pagination";

const Jobs = ({ userDetails, navigate }) => {
  const jwtToken = userDetails.jwttoken;
  const [searchValue, setSearchValue] = useState(null);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState(1);
  const [filter, setFilter] = useState(
    window.history.state?.usr?.selectedFilter ?? "open"
  );
  const [sort, setSort] = useState("");
  const [jobs, setJobs] = useState([]);
  const [filterCount, setFilterCount] = useState({
    totalDocs: 0,
    openJobs: 0,
    closedJobs: 0,
    agreed: 0,
    paidJobs: 0,
    completedJobs: 0,
    refundRequestedJobs: 0,
    transferNeededJobs: 0,
  });

  const searchJob = useCallback(() => {
    setSearch(searchValue);
    setFilter(searchValue === "" ? "open" : null);
    setPage(1);
  }, [searchValue]);

  const sortJobs = useCallback(
    (event, field) => {
      if (event) event.preventDefault();

      const sortFields = {
        name: { asc: "name=1", desc: "name=-1" },
        jobDate: { asc: "jobDate=1", desc: "jobDate=-1" },
        creationDate: { asc: "creationDate=1", desc: "creationDate=-1" },
        location: { asc: "location=1", desc: "location=-1" },

        quotes: { asc: "quotes=1", desc: "quotes=-1" },
      };

      const currentSort = sortFields[field];
      if (!currentSort) return;

      const newSort =
        sort === currentSort.asc ? currentSort.desc : currentSort.asc;

      setSearch("");
      setSort(newSort);
      setPage(1);
    },
    [sort]
  );

  const getJobs = () => {
    showSpinner();

    const queryParams = {
      search,
      filter,
      sort,
      page: page,
      limit: pageSize,
    };

    getretrieveJobs(queryParams, getJobs_done, userDetails.jwttoken);
  }

  const getJobs_done = (resStatus, responseString, resJson) => {
    closeSpinner();

    if (resStatus === 401) {
      modal(sessionExpired);
      toScreen(false, navigate, "/logout");
    } else if (resStatus === "400") {
      setJobs([]);
      alert(responseString);
    } else if (resStatus === 200) {
      setTotalPages(Math.ceil(resJson?.totalRecords / pageSize));
      setJobs(resJson?.data || []);
    }
  };

  const fetchFilterCount = useCallback(() => {
    showSpinner();

    getJobsRecordsCount(handleFilterCount_done, jwtToken);
  }, [jwtToken]);

  // Handle filter count response
  const handleFilterCount_done = useCallback(
    (resStatus, responseString, resJson) => {
      closeSpinner();

      if (resStatus === 401) {
        modal(sessionExpired);
        toScreen(false, navigate, "/logout");
      } else if (resStatus === "400") {
        alert(responseString);
      } else if (resStatus === 200) {
        setFilterCount({
          totalDocs: resJson?.totalDocs,
          openJobs: resJson?.openJobs,
          closedJobs: resJson?.closedJobs,
          paidJobs: resJson?.paidJobs,
          completedJobs: resJson?.completedJobs,
          refundRequestedJobs: resJson?.refundRequestedJobs,
          transferNeededJobs: resJson?.transferNeededJobs,
        });
      }
    },
    [navigate]
  );

  const renderJobs = useCallback((job, key) => (
    <div
      className={
        job.isPaused ? "c-list-entry u-color-grey-dark" : "c-list-entry"
      }
      key={key}
    >
      <div className="c-list-entry__name">
        {(job.refundRequested &&
          (!job.refundAuthorisation ||
            job.refundAuthorisation === "pending")) ||
          (job.status === "complete" &&
            job.paymentType === "fullpayment" &&
            (!job.mavPayment || job.mavPayment === "")) ? (
          <b className="u-before-icon-exclamation u-color-red">
            &nbsp;&nbsp;{job?.firstname}
          </b>
        ) : job.isPaused ? (
          <b className="u-before-icon-pause text-capitalize">&nbsp;&nbsp;{job?.firstname || "-"}</b>
        ) : (
          <b>{job?.firstname}</b>
        )}
      </div>
      <div className="c-list-entry__jobdate">
        <span className="u-before-icon-calendar2">&nbsp;&nbsp;{moment(job?.jobDate)?.tz("Europe/London")?.format("DD MMM YYYY")}</span>
        <br />
        <span className="u-before-icon-clock">
          &nbsp;&nbsp;
          {moment(job?.jobDate)?.tz("Europe/London")?.format("hh:mm ")}
        </span>
      </div>
      <div className="c-list-entry__location">
        <span className="u-before-icon-location text-capitalize">&nbsp;&nbsp;

          {`${job?.originAddress || ""} ${job?.originAddress || job?.destinationAddress ? "→" : ""
            } ${job?.destinationAddress || ""}`}
        </span>
      </div>
      <div className="c-list-entry__created">
        <span className="u-before-icon-calendar2">
          &nbsp;&nbsp;
          {moment(job?.jobCreationDate)?.tz("Europe/London")?.format("DD MMM YYYY")}
        </span>
        <br />
        <span className="u-before-icon-clock">
          &nbsp;&nbsp;
          {moment(job?.jobCreationDate)?.tz("Europe/London")?.format("hh:mm ")}
        </span>
      </div>
      <div className="c-list-entry__quotes">
        <b>{job?.totalQuotes ?? 0}</b>
      </div>
      <button
        type="button"
        className="c-btn c-btn--teal c-btn--small me-0"
        onClick={(e) => toScreen(e, navigate, "/job/" + job?.shortId)}
      >
        View
      </button>
    </div>
  ));

  const handleInputChange = useCallback((event) => {
    const { name, value, type, checked } = event.target;

    const inputValue = type === "checkbox" ? checked : value;

    if (name === "search") {
      setSearchValue(inputValue);
    } else if (name === "filter") {
      setSearch("");
      setSearchValue(null);
      setFilter(inputValue);
      setPage(1);
    } else if (name === "mode") {
      setMode(inputValue);
    }
  }, []);

  const getSortClass = useCallback(
    (key) => {
      if (sort === `${key}=1`) {
        return "c-list-filter__button c-list-filter__button--icon-up";
      } else if (sort === `${key}=-1`) {
        return "c-list-filter__button c-list-filter__button--icon-down";
      }
      return "c-list-filter__button c-list-filter__button--faded";
    },
    [sort]
  );

  useEffect(() => {
    setInputValue(page);
  }, [page]);

  useEffect(() => {
    getJobs();
  }, [filter, page, pageSize, sort]);

  useEffect(() => {
    fetchFilterCount();
  }, [fetchFilterCount]);

  return (
    <div className="l-base-layout__content">
      <h1>Admin / Jobs</h1>

      <div className="c-hr"></div>

      <div className="c-subheader-info-panel">
        <div className="c-subheader-info-panel__primary">
          Total: {filterCount?.totalDocs}
        </div>
        <div className="c-subheader-info-panel__search">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              searchJob();
            }}
          >
            <input
              type="text"
              value={searchValue || ""}
              onChange={handleInputChange}
              name="search"
              placeholder=""
              className="c-subheader-info-panel__input"
            />
            <button
              type="button"
              className="c-btn-outline c-btn-outline--white c-btn-outline--small"
              onClick={() => searchValue !== null && searchJob()}
            >
              Search
            </button>
          </form>
        </div>
      </div>

      <div className="c-hr"></div>

      <div className="l-row">
        <div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
          <div className="filter-bar">
            <div className="c-checkbox-radio-block">
              <input
                type="radio"
                id="open"
                name="filter"
                value="open"
                onChange={handleInputChange}
                checked={filter === "open"}
              />
              <label htmlFor="open">Open ({filterCount?.openJobs})</label>
            </div>
            <div className="c-checkbox-radio-block">
              <input
                type="radio"
                id="paid"
                name="filter"
                value="paid"
                onChange={handleInputChange}
                checked={filter === "paid"}
              />
              <label htmlFor="paid">Paid ({filterCount?.paidJobs})</label>
            </div>
            <div className="c-checkbox-radio-block">
              <input
                type="radio"
                id="complete"
                name="filter"
                value="complete"
                onChange={handleInputChange}
                checked={filter === "complete"}
              />
              <label htmlFor="complete">
                Complete ({filterCount?.completedJobs})
              </label>
            </div>
            <div className="c-checkbox-radio-block">
              <input
                type="radio"
                id="closed"
                name="filter"
                value="closed"
                onChange={handleInputChange}
                checked={filter === "closed"}
              />
              <label htmlFor="closed">Closed ({filterCount?.closedJobs})</label>
            </div>
            <div className="c-checkbox-radio-block">
              <input
                type="radio"
                id="refundRequested"
                name="filter"
                value="refundRequested"
                onChange={handleInputChange}
                checked={filter === "refundRequested"}
              />
              <label
                htmlFor="refundRequested"
                className={
                  filterCount?.refundRequestedJobs &&
                    filterCount?.refundRequestedJobs > 0
                    ? "u-color-red"
                    : null
                }
              >
                Refund Requested ({filterCount?.refundRequestedJobs})
              </label>
            </div>
            <div className="c-checkbox-radio-block">
              <input
                type="radio"
                id="transferNeeded"
                name="filter"
                value="transferNeeded"
                onChange={handleInputChange}
                checked={filter === "transferNeeded"}
              />
              <label
                htmlFor="transferNeeded"
                className={
                  filterCount?.transferNeededJobs &&
                    filterCount?.transferNeededJobs > 0
                    ? "u-color-red"
                    : null
                }
              >
                Transfer Needed ({filterCount?.transferNeededJobs})
              </label>
            </div>
          </div>
        </div>

        <div className="l-col-100">


          <div className="c-hr u-margin-bottom-0"></div>

          <div>
            <div className="c-list-entry c-list-entry__header">
              <div className="c-list-entry__name c-list-filter">
                <button
                  className={getSortClass("name")}
                  onClick={(e) => sortJobs(e, "name")}
                >
                  Name
                </button>
              </div>
              <div className="c-list-entry__jobdate c-list-filter">
                <button
                  className={getSortClass("jobDate")}
                  onClick={(e) => sortJobs(e, "jobDate")}
                >
                  Job date
                </button>
              </div>
              <div className="c-list-entry__location c-list-filter">
                <button
                  className={getSortClass("location")}
                  onClick={(e) => sortJobs(e, "location")}
                >
                  Location
                </button>
              </div>
              <div className="c-list-entry__created c-list-filter">
                <button
                  className={getSortClass("creationDate")}
                  onClick={(e) => sortJobs(e, "creationDate")}
                >
                  Created
                </button>
              </div>
              <div className="c-list-entry__quotes c-list-filter">
                <button
                  className={getSortClass("quotes")}
                  onClick={(e) => sortJobs(e, "quotes")}
                >
                  Quotes
                </button>
              </div>
            </div>
          </div>

          <div>
            {jobs?.length > 0 ? (
              jobs?.map(renderJobs)
            ) : (
              <div className="u-text-align-center u-margin-top-bottom-m u-color-red">
                No jobs found
              </div>
            )}
          </div>

          <div className="c-hr u-margin-bottom-0"></div>

          <Pagination
            pageSize={pageSize}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
};

Jobs.propTypes = {
  userDetails: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default withNavigate(Jobs);
